@media screen and (min-width:1500px) {
        /* HTML */
        html {
            font-size: 115%;
        }
        /* GRID TYPE 2 */
        .grid-container-type-2 {
            margin-left: 1.5em;
            margin-right: 1.5em;
            padding-bottom: 1.5em;

            grid-template-columns: 5fr 4fr;
            grid-column-gap: 0.75em;
          }
        /* TIME TABLE & PRICING TABLE */
        .time-table,
        .pricing-table {
            padding-left: 1.5em;
            padding-right: 1.5em;
        }
}